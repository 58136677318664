<template>
	<div>
		<BillFunction :pid="pid" :showType="showType" :queryInfo="queryInfo" :getBillInfo="getBillInfo">
			<template slot-scope="{total, handleCurrentChange, handleSizeChange, queryInfo}">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryInfo.currentPage" :page-sizes="[10, 20, 50, 100]"
					:page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</template>
		</BillFunction>
	</div>
</template>

<script>
import BillFunction from '@/components/PropertyTab/utilities/BillFunction.vue';
export default {
	name: 'BillTab',
	props: ['pid', 'getBillInfo', 'queryInfo'],
	components: { BillFunction },
	data() {
		return {
			showType: 2,
		};
	}
}
</script>