import vue from '../main';
export default {
  namespaced: true,
  actions: {
    async getIncomesInSix(context, pid) {
      const {data: res} = await vue.$http.get(`properties/incomelistinsix/${pid}`);
      if (res.meta.status !== 200) return vue.$message.error('获取6个月收入列表失败！');
      context.commit('SAVE_INCOMEINSIXMONTH', {list: res.data, total: res.data.length});
    }
  },
  mutations: {
    SAVE_INCOMELIST(state, value) {
      state.incomeList = value;
    },
    SAVE_INCOMETOTAL(state, value) {
      state.incomeTotal = value;
    },
    SAVE_INCOMEINSIXMONTH(state, value) {
      state.incomeInSix = value;
    }
  },
  state: {
    incomeList: [],
    incomeTotal: 0,
    incomeInSix: {}
  },
  getters: {}
};