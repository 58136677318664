<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>员工管理</el-breadcrumb-item>
			<el-breadcrumb-item>员工列表</el-breadcrumb-item>
		</el-breadcrumb>

		<el-card>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-input placeholder="请输姓名关键字" v-model="queryInfo.query" clearable
						@clear="handleSearchClear">
						<el-button slot="append" icon="el-icon-search" @click="getUserlist"></el-button>
					</el-input>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" @click="addDialogVisible = true">添加员工</el-button>
				</el-col>
			</el-row>

			<el-table :data="userlist" border stripe @sort-change="changeSort"
				:highlight-current-row="true" v-loading="loading">
				<el-table-column type="index"></el-table-column>
				<el-table-column label="姓名" prop="username" sortable="custom" min-width="200">
				</el-table-column>
				<el-table-column label="邮箱" prop="email" min-width="250" sortable="custom">
				</el-table-column>
				<el-table-column label="电话" prop="mobile" min-width="200"></el-table-column>
				<el-table-column label="角色" prop="role_name" width="100"></el-table-column>
				<el-table-column label="状态" prop="mg_state" width="65">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.mg_state" @change="userStateChanged(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="180">
					<template slot-scope="scope">
						<el-tooltip effect="dark" content="修改" placement="top" :enterable="false">
							<el-button type="primary" icon="el-icon-edit" size="mini"
								@click="showEditDialog(scope.row)">
							</el-button>
						</el-tooltip>
						<el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
							<el-button type="danger" icon="el-icon-delete" size="mini"
								@click="removeUserById(scope.row.id)">
							</el-button>
						</el-tooltip>
						<el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
							<el-button type="warning" icon="el-icon-setting" size="mini"
								@click="setRole(scope.row)">
							</el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.pagenum" :page-sizes="[5, 10, 20, 50]"
				:page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>

		<el-dialog title="添加员工" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
			<el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="名字" prop="firstname">
							<el-input v-model="addForm.firstname"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="姓氏" prop="lastname">
							<el-input v-model="addForm.lastname"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="邮箱" prop="email">
							<el-input v-model="addForm.email"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="电话" prop="mobile">
							<el-input v-model="addForm.mobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="密码" prop="password">
							<el-input type="password" v-model="addForm.password" autocomplete="new-password">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="确认密码" prop="checkpass">
							<el-input type="password" v-model="addForm.checkpass" autocomplete="new-password">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-row></el-row>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addUser">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="修改信息" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
			<el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="名字" prop="firstname">
							<el-input v-model="editForm.firstname"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="姓氏" prop="lastname">
							<el-input v-model="editForm.lastname"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="邮箱" prop="email">
							<el-input v-model="editForm.email"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="电话" prop="mobile">
							<el-input v-model="editForm.mobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="新密码" prop="password">
							<el-input type="password" v-model="editForm.password" autocomplete="new-password">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="确认密码" prop="checkpass">
							<el-input type="password" v-model="editForm.checkpass" autocomplete="new-password">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editUserInfo">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="分配角色" :visible.sync="setRoleDialogVisible" width="50%"
			@close="setRoleDialogClosed">
			<div>
				<p>当前的用户：{{userInfo.username}}</p>
				<br>
				<p>当前的角色：{{userInfo.role_name}}</p>
				<br>
				<p>分配新角色：
					<el-select v-model="selectedRoleId" placeholder="请选择">
						<el-option v-for="item in rolesList" :key="item.id" :label="item.roleName"
							:value="item.id">
						</el-option>
					</el-select>
				</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setRoleDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveRoleInfo">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'Users',
	data() {
		const validatePass = (rule, value, callback) => {
			if (value !== this.addForm.password) {
				callback(new Error('两次输入密码不一致！'));
			} else {
				callback();
			}
		};
		const editValidatePass = (rule, value, callback) => {
			if (!this.editForm.password) {
				if (value) {
					callback(new Error('两次输入密码不一致！'));
				} else {
					callback();
				}
			} else if (value !== this.editForm.password) {
				callback(new Error('两次输入密码不一致！'));
			} else {
				callback();
			}
		};
		return {
			queryInfo: {
				query: '',
				pagenum: 1,
				pagesize: 10,
				order: '',
				prop: ''
			},
			loading: false,
			userlist: [],
			total: 0,
			addDialogVisible: false,
			addForm: {
				firstname: '',
				lastname: '',
				password: '',
				checkpass: '',
				email: '',
				mobile: ''
			},
			addFormRules: {
				firstname: [
					{required: true, message: '请输入用户名称', trigger: 'blur'},
				],
				lastname: [
					{required: true, message: '请输入用户名称', trigger: 'blur'},
				],
				password: [
					{required: true, message: '请输入密码', trigger: 'blur'},
					{min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'}
				],
				checkpass: [
					{required: true, message: '请确认密码', trigger: 'blur'},
					{validator: validatePass, trigger: 'blur'}
				],
				email: [
					{required: true, message: '请输入邮箱', trigger: 'blur'},
					{type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur']}
				]
			},
			editDialogVisible: false,
			editForm: {},
			editFormRules: {
				firstname: [
					{required: true, message: '请输入用户名称', trigger: 'blur'},
				],
				lastname: [
					{required: true, message: '请输入用户名称', trigger: 'blur'},
				],
				password: [
					{min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'}
				],
				checkpass: [
					{validator: editValidatePass, trigger: 'blur'}
				],
				email: [
					{required: true, message: '请输入邮箱', trigger: 'blur'},
					{type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur']}
				],
			},
			setRoleDialogVisible: false,
			userInfo: {},
			rolesList: [],
			selectedRoleId: ''
		};
	},
	methods: {
		async getUserlist() {
			this.loading = true;
			const {data: res} = await this.$http.get('users', {params: this.queryInfo});
			if (res.meta.status !== 200) return this.$message.error('获取用户列表失败！');
			this.userlist = res.data.users;
			this.total = res.data.total;
			this.loading = false;
		},
		handleSizeChange(newSize) {
			// console.log(newSize);
			this.queryInfo.pagesize = newSize;
			this.getUserlist();
		},
		handleCurrentChange(newPage) {
			// console.log(newPage);
			this.queryInfo.pagenum = newPage;
			this.getUserlist();
		},
		handleSearchClear() {
			this.queryInfo.pagenum = 1;
			this.getUserlist();
		},
		async userStateChanged(userinfo) {
			const {data: res} = await this.$http.put(`users/${userinfo.id}/state/${userinfo.mg_state}`);
			if (res.meta.status !== 200) {
				userinfo.mg_state = !userinfo.mg_state;
				return this.$message.error('更新用户状态失败！');
			}
			this.$message.success('更新用户状态成功！');
		},
		addDialogClosed() {
			this.$refs.addFormRef.resetFields();
		},
		addUser() {
			this.$refs.addFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.post('users', this.addForm);
				// console.log(res);
				if (res.meta.status !== 201) {
					if (res.meta.status === 422) {
						return this.$message.error(res.meta.msg);
					} else {
						return this.$message.error('添加员工失败！');
					}
				}
				this.$message.success('添加用户成功！');
				this.addDialogVisible = false;
				this.getUserlist();
			});
		},
		async showEditDialog(userInfo) {
			this.editForm = {...userInfo};
			this.editDialogVisible = true;
		},
		editDialogClosed() {
			this.$refs.editFormRef.resetFields();
		},
		editUserInfo() {
			this.$refs.editFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.put('users/' + this.editForm.id, this.editForm);
				if (res.meta.status !== 200) {
					if (res.meta.status === 422) {
						return this.$message.error(res.meta.msg);
					} else {
						return this.$message.error('更新用户失败！');
					}
				}
				this.$message.success('更新用户成功！');
				this.editDialogVisible = false;
				this.getUserlist();
			});
		},
		async removeUserById(id) {
			const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).catch(err => err);

			// 如果用户确认删除， 则返回值为字符串 confirm
			// 如果用户取消了删除，则返回值为字符串 cancel
			if (confirmResult !== 'confirm') {
				return this.$message.info('已取消删除！');
			}

			const {data: res} = await this.$http.delete('users/' + id);
			if (res.meta.status !== 200) {
				return this.$message.error('用户删除失败!');
			}
			this.$message.success('用户已成功删除！');
			this.getUserlist();

		},
		async setRole(userInfo) {
			this.userInfo = userInfo;
			const {data: res} = await this.$http.get('roles');
			if (res.meta.status !== 200) return this.$message.error('获取角色列表失败！');
			this.rolesList = res.data;
			this.setRoleDialogVisible = true;
		},
		async saveRoleInfo() {
			if (!this.selectedRoleId) return this.$message.error('请选择要分配的角色！');
			const {data: res} = await this.$http.put(`roles/${this.userInfo.id}/role`, {rid: this.selectedRoleId});
			if (res.meta.status !== 200) {
				return this.$message.error('分配用户角色失败！');
			}
			this.$message.success('分配用户角色成功！');
			this.getUserlist();
			this.setRoleDialogVisible = false;
		},
		setRoleDialogClosed() {
			this.selectedRoleId = '',
				this.userInfo = {};
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getUserlist();
		}
	},
	created() {
		this.getUserlist();
	}
}
</script>

<style lang="less" scoped>
</style>