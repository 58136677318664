import vue from '../main';
export default {
  namespaced: true,
  actions: {
    async getChecksInSix(context, pid) {
      const {data: res} = await vue.$http.get(`properties/getchecksinsix/${pid}`);
      if (res.meta.status !== 200) return vue.$message.error('获取6个月账单列表失败！');
      context.commit('SAVE_CHECKINSIXMONTH', {list: res.data, total: res.data.length});
    }
  },
  mutations: {
    SAVE_CHECKLIST(state, value) {
      state.checkList = value;
    },
    SAVE_CHECKTOTAL(state, value) {
      state.checkTotal = value;
    },
    SAVE_CHECKINSIXMONTH(state, value) {
      state.checksInSix = value;
    }
  },
  state: {
    checkList: [],
    checkTotal: 0,
    checksInSix: {}
  },
  getters: {}
};