<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>房产管理</el-breadcrumb-item>
			<el-breadcrumb-item :to="{path: '/properties'}">房产列表</el-breadcrumb-item>
			<el-breadcrumb-item>房产详情</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-tabs>
				<el-tab-pane label="房产概况">
					<BaseInfo :pid="pid" v-loading="loading1" :getBaseinfo="getBaseinfo" />
					<el-divider></el-divider>
					<LeaseInfo :pid="pid" :getLeaseInfo="getLeaseInfo" :queryInfo="queryInfo1"
						v-loading="loading2" />
					<el-divider></el-divider>
					<BillInfo :pid="pid" :getBillInfo="getBillInfo" :queryInfo="queryInfo2"
						v-loading="loading3" />
					<el-divider></el-divider>
					<IncomeInfo :pid="pid" :getIncomeList="getIncomeList" :queryInfo="queryInfo4"
						v-loading="loading5" />
					<el-divider></el-divider>
					<CheckInfo :pid="pid" :getCheckList="getCheckList" :queryInfo="queryInfo3"
						v-loading="loading4" />
					<div class="footer">
						以上为房产概况
					</div>
				</el-tab-pane>
				<el-tab-pane label="租约管理">
					<LeaseTab :pid="pid" :getLeaseInfo="getLeaseInfo" :queryInfo="queryInfo1"
						v-loading="loading2" />
				</el-tab-pane>
				<el-tab-pane label="账单管理">
					<BillTab :pid="pid" :getBillInfo="getBillInfo" :queryInfo="queryInfo2"
						v-loading="loading3" />
				</el-tab-pane>
				<el-tab-pane label="租金收入">
					<IncomeTab :pid="pid" :getIncomeList="getIncomeList" :queryInfo="queryInfo4"
						v-loading="loading5" />
				</el-tab-pane>
				<el-tab-pane label="月付支票">
					<CheckTab :pid="pid" :getCheckList="getCheckList" :queryInfo="queryInfo3"
						v-loading="loading4" />
				</el-tab-pane>
			</el-tabs>
		</el-card>

	</div>
</template>

<script>
import BaseInfo from '@/components/PropertyTab/BaseInfo';
import LeaseInfo from '@/components/PropertyTab/LeaseInfo';
import BillInfo from '@/components/PropertyTab/BillInfo';
import CheckInfo from '@/components/PropertyTab/CheckInfo';
import IncomeInfo from '@/components/PropertyTab/IncomeInfo';
import LeaseTab from '@/components/LeaseTab';
import BillTab from '@/components/BillTab';
import CheckTab from '@/components/CheckTab';
import IncomeTab from '@/components/IncomeTab';
import {mapActions, mapMutations} from 'vuex';
export default {
	name: 'Detail',
	components: {BaseInfo, LeaseInfo, BillInfo, CheckInfo, IncomeInfo, LeaseTab, BillTab, CheckTab, IncomeTab},
	props: ['pid'],
	created() {
		this.getBaseinfo();
		this.getLeaseInfo();
		this.getBillInfo();
		this.getCheckList();
		this.getIncomeList();
	},
	data() {
		return {
			loading1: false,
			loading2: false,
			loading3: false,
			loading4: false,
			loading5: false,
			queryInfo1: {
				date_start: null,
				date_end: null,
				currentPage: 1,
				pageSize: 10,
				order: '',
				prop: ''
			},
			queryInfo2: {
				date_start: null,
				date_end: null,
				currentPage: 1,
				pageSize: 10,
				order: '',
				prop: ''
			},
			queryInfo3: {
				date_start: null,
				date_end: null,
				currentPage: 1,
				pageSize: 10,
				order: '',
				prop: ''
			},
			queryInfo4: {
				date_start: null,
				date_end: null,
				currentPage: 1,
				pageSize: 10,
				order: '',
				prop: ''
			},
		};
	},
	methods: {
		...mapMutations('prop', {saveBaseInfo: 'SAVE_BASEINFO'}),
		async getBaseinfo() {
			this.loading1 = true;
			const {data: res} = await this.$http.get('properties/baseinfo/' + this.pid);
			if (res.meta.status !== 200) return this.$message.error('获取房产信息失败！');
			this.saveBaseInfo(res.data);
			this.loading1 = false;
		},
		...mapActions('lease', ['getActiveLease']),
		...mapMutations('lease', {saveLeaseInfo: 'SAVE_LEASEINFO', saveLeaseTotal: 'SAVE_LEASETOTAL'}),
		async getLeaseInfo() {
			this.loading2 = true;
			const {data: res} = await this.$http.get('properties/leaseinfo/' + this.pid, {params: this.queryInfo1});
			if (res.meta.status !== 200) return this.$message.error('获取租约信息失败！');
			this.saveLeaseInfo(res.data.lease_infos);
			this.saveLeaseTotal(res.data.total);
			await this.getActiveLease(this.pid);
			this.loading2 = false;
		},
		...mapActions('bill', ['getBillsInMonth']),
		...mapMutations('bill', {saveBillList: 'SAVE_BILLLIST', saveBillTotal: 'SAVE_BILLTOTAL'}),
		async getBillInfo() {
			this.loading3 = true;
			const {data: res} = await this.$http.get('properties/billinfo/' + this.pid, {params: this.queryInfo2});
			if (res.meta.status !== 200) return this.$message.error('获取账单列表失败！');
			this.saveBillList(res.data.bills);
			this.saveBillTotal(res.data.total);
			await this.getBillsInMonth(this.pid);
			this.loading3 = false;
		},
		...mapActions('check', ['getChecksInSix']),
		...mapMutations('check', {saveCheckList: 'SAVE_CHECKLIST', saveCheckTotal: 'SAVE_CHECKTOTAL'}),
		async getCheckList() {
			this.loading4 = true;
			const {data: res} = await this.$http.get('properties/checkinfo/' + this.pid, {params: this.queryInfo3});
			if (res.meta.status !== 200) return this.$message.error('获取支票列表失败！');
			this.saveCheckList(res.data.checks);
			this.saveCheckTotal(res.data.total);
			await this.getChecksInSix(this.pid);
			this.loading4 = false;
		},
		...mapActions('income', ['getIncomesInSix']),
		...mapMutations('income', {saveIncomeList: 'SAVE_INCOMELIST', saveIncomeTotal: 'SAVE_INCOMETOTAL'}),
		async getIncomeList() {
			this.loading5 = true;
			const {data: res} = await this.$http.get('properties/incomeinfo/' + this.pid, {params: this.queryInfo4});
			if (res.meta.status !== 200) return this.$message.error('获取收入列表失败！');
			this.saveIncomeList(res.data.incomes);
			this.saveIncomeTotal(res.data.total);
			await this.getIncomesInSix(this.pid);
			this.loading5 = false;
		}
	}
}
</script>

<style lang="less" scoped>
.el-divider {
	margin: 100px 0 50px;
	height: 2px;
}
.footer {
	margin: 50px 0;
	text-align: center;
	font-weight: 700;
}
</style>