import vue from '../main';
export default {
  namespaced: true,
  actions: {
    async getActiveLease(context, pid) {
      const {data: res} = await vue.$http.get(`/properties/curlease/${pid}`);
      if (res.meta.status !== 200) return vue.$message.error('获取有效租约信息失败！');
      context.commit('SAVE_CURRENTLEASE', res.data);
    }
  },
  mutations: {
    SAVE_LEASEINFO(state, value) {
      state.leaseList = value;
    },
    SAVE_LEASETOTAL(state, value) {
      state.leaseTotal = value;
    },
    SAVE_CURRENTLEASE(state, value) {
      state.leaseInfo = value;
    }
  },
  state: {
    leaseList: [],
    leaseTotal: 0,
    leaseInfo: {}
  },
  getters: {}
};