<template>
	<div>
		<div v-if="showType === 1" class="title1">
			<span>3. 两个月内的账单预览</span>
			<el-button type="primary" @click="addDialogVisible = true" size="small">添加账单
			</el-button>
		</div>

		<div v-if="showType === 2">
			<div class="title2">
				<span>账单列表，默认显示所有账单</span>
			</div>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-date-picker v-model="dateRange" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels style="width: 100%"
						@change="timeChange">
					</el-date-picker>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" @click="getBillInfo" icon="el-icon-search">范围搜索</el-button>
				</el-col>
				<el-col :span="12" class="header-last">
					<el-button type="warning" @click="addDialogVisible = true">添加账单</el-button>
				</el-col>
			</el-row>
		</div>

		<el-table :data="showType === 2 ? billList : billsInMonth.list" border @sort-change="changeSort"
			style="width: 100%" :max-height="showType === 1 ? '350' : '100%'">
			<el-table-column type="index"></el-table-column>
			<el-table-column prop="bill_date" label="签发日" sortable="custom" width="100"
				:formatter="formatDate">
			</el-table-column>
			<el-table-column prop="due_date" label="到期日" sortable="custom" width="100"
				:formatter="formatDate">
			</el-table-column>
			<el-table-column prop="bill_type" label="账单名目" width="245">
			</el-table-column>
			<el-table-column prop="bill_amount" label="金额" width="80">
			</el-table-column>
			<el-table-column prop="note" label="备注" min-width="400">
			</el-table-column>
			<el-table-column label="逾期" fixed="right" width="80">
				<template slot-scope="scope">
					<el-tag v-if="validateOverDue(scope.row)" :type="scope.row.is_paid ? 'info' : 'danger'"
						size="medium" effect="dark">已逾期
					</el-tag>
					<el-tag v-else :type="scope.row.is_paid ? 'info' : 'success'" size="medium" effect="dark">
						未逾期</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="is_paid" label="付款" fixed="right" width="80" sortable="custom">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.is_paid" type="success" size="medium">已付</el-tag>
					<el-tag v-else type="danger" size="medium">未付</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="dl_url" label="账单下载" width="80" fixed="right">
				<template slot-scope="scope">
					<span v-if="!scope.row.dl_url || scope.row.dl_url === 'N/A'">N/A</span>
					<el-link v-else type="primary" :href="scope.row.dl_url">下载
					</el-link>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="240" fixed="right">
				<template slot-scope="scope">
					<el-tooltip effect="dark" content="上传PDF" placement="top" :enterable="false">
						<el-button type="warning" icon="el-icon-upload" size="mini"
							@click="showUploadDialog(scope.row)">
						</el-button>
					</el-tooltip>
					<el-tooltip effect="dark" content="删除PDF" placement="top" :enterable="false">
						<el-button type="info" icon="el-icon-close" size="mini"
							@click="deletePdfFile(scope.row.id)">
						</el-button>
					</el-tooltip>
					<el-tooltip effect="dark" content="修改账单" placement="top" :enterable="false">
						<el-button type="primary" icon="el-icon-edit" size="mini"
							@click="showEditDialog(scope.row)">
						</el-button>
					</el-tooltip>
					<el-tooltip effect="dark" content="删除账单" placement="top" :enterable="false">
						<el-button type="danger" icon="el-icon-delete" size="mini"
							@click="removeBillById(scope.row.id)">
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>

		<slot :total="showType === 2 ? billTotal : billsInMonth.total"
			:handleCurrentChange="handleCurrentChange" :handleSizeChange="handleSizeChange"
			:queryInfo="queryInfo"></slot>

		<el-dialog title="添加账单" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
			<el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="签发日" prop="bill_date">
							<el-date-picker v-model="addForm.bill_date" align="left" type="date"
								placeholder="选择日期" :picker-options="pickerOptionsAdd1" format="MM-dd-yyyy"
								style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="到期日" prop="due_date">
							<el-date-picker v-model="addForm.due_date" align="left" type="date" placeholder="选择日期"
								:picker-options="pickerOptionsAdd2" format="MM-dd-yyyy" style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span='11'>
						<el-form-item label="账单名目" prop="bill_type">
							<el-select v-model="addForm.bill_type" clearable placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="金额" prop="bill_amount">
							<el-input v-model="addForm.bill_amount" placeholder="输入数字，不要带符号！"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="支付完成" prop="is_paid">
							<el-switch v-model="addForm.is_paid" disabled>
							</el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="22">
						<el-form-item label="备注" prop="note">
							<el-input type="textarea" placeholder="请输入内容" v-model="addForm.note" maxlength="150"
								show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 100%;">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addBill">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="修改账单" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
			<el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="签发日" prop="bill_date">
							<el-date-picker v-model="editForm.bill_date" align="left" type="date"
								placeholder="选择日期" :picker-options="pickerOptionsEdit1" format="MM-dd-yyyy"
								style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="到期日" prop="due_date">
							<el-date-picker v-model="editForm.due_date" align="left" type="date"
								placeholder="选择日期" :picker-options="pickerOptionsEdit2" format="MM-dd-yyyy"
								style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span='11'>
						<el-form-item label="账单名目" prop="bill_type">
							<el-select v-model="editForm.bill_type" clearable placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="金额" prop="bill_amount">
							<el-input v-model="editForm.bill_amount" placeholder="输入数字，不要带符号！"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="支付完成" prop="is_paid">
							<el-switch v-model="editForm.is_paid">
							</el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="22">
						<el-form-item label="备注" prop="note">
							<el-input type="textarea" placeholder="请输入内容" v-model="editForm.note" maxlength="150"
								show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 100%;">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editBill">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog v-loading='uploadLoading' title="上传账单PDF文件" :visible.sync="uploadDialogVisible"
			width="50%">
			<el-form :model="uploadForm" :rules="uploadFormRules" ref="uploadFormRef" label-width="80px">
				<el-form-item label="请选择文件" label-width="100px">
					<el-upload ref="upload" action="" :on-remove="handleRemove" :on-change="fileChange"
						:auto-upload="false" :limit="1" :on-exceed="handleExceed" :file-list="fileList">
						<el-button size="small" type="success">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">只能上传1个PDF文件，且不超过1024kb</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="uploadFile">上 传</el-button>
				<el-button @click="uploadDialogClosed">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {createLogger, mapState} from 'vuex';
export default {
	name: 'BillFunction',
	props: ['pid', 'showType', 'queryInfo', 'getBillInfo'],
	data() {
		const validateNumber = (rule, value, callback) => {
			if (isNaN(value)) {
				callback(new Error('请输入数值！'));
			} else {
				callback();
			}
		};
		return {
			dateRange: [],
			options: [{
				value: 'Management fees',
				label: 'Management fees'
			}, {
				value: 'Cleaning and Maintenance',
				label: 'Cleaning and Maintenance'
			}, {
				value: 'Repairs',
				label: 'Repairs'
			}, {
				value: 'Leasing commissions',
				label: 'Leasing commissions'
			}, {
				value: 'Property Insurance',
				label: 'Property Insurance'
			}, {
				value: 'Property Taxes',
				label: 'Property Taxes'
			}, {
				value: 'Sale Tax',
				label: 'Sale Tax'
			}, {
				value: 'Remodel',
				label: 'Remodel'
			}, {
				value: 'HOA',
				label: 'HOA'
			}, {
				value: 'Utilities',
				label: 'Utilities'
			}, {
				value: 'Credit card process fee',
				label: 'Credit card process fee'
			}, {
				value: 'Legal and other professional fees',
				label: 'Legal and other professional fees'
			}, {
				value: 'Other fees',
				label: 'Other fees'
			}],
			addDialogVisible: false,
			addForm: {
				bill_date: '',
				due_date: '',
				bill_type: '',
				bill_amount: null,
				note: '',
				is_paid: false,
			},
			addFormRules: {
				bill_date: [
					{required: true, message: '签发日期必须输入！', trigger: 'blur'}
				],
				bill_type: [
					{required: true, message: '账单名目必须输入！', trigger: 'blur'}
				],
				bill_amount: [
					{required: true, message: '账单金额必须输入！', trigger: 'blur'},
					{validator: validateNumber, trigger: 'blur'}
				]
			},
			editDialogVisible: false,
			editForm: {},
			editFormRules: {
				bill_date: [
					{required: true, message: '签发日期必须输入！', trigger: 'blur'}
				],
				bill_type: [
					{required: true, message: '账单名目必须输入！', trigger: 'blur'}
				],
				bill_amount: [
					{required: true, message: '账单金额必须输入！', trigger: 'blur'},
					{validator: validateNumber, trigger: 'blur'}
				]
			},
			uploadForm: {},
			uploadFormRules: {},
			fileList: [],
			uploadLoading: false,
			pickerOptionsAdd1: {
				disabledDate: (time) => {
					if (this.addForm.due_date) {
						return time.getTime() >= this.addForm.due_date;
					}
				},
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						picker.$emit('pick', date);
					}
				}, {
					text: '昨天',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setTime(date.getTime() - 3600 * 1000 * 24);
						picker.$emit('pick', date);
					}
				}, {
					text: '一周前',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', date);
					}
				}]
			},
			pickerOptionsAdd2: {
				disabledDate: (time) => {
					if (this.addForm.bill_date) {
						return time.getTime() <= this.addForm.bill_date;
					}
				},
				shortcuts: [{
					text: '一周后',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
						picker.$emit('pick', date);
					}
				}, {
					text: '一个月后',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setMonth(date.getMonth() + 1);
						picker.$emit('pick', date);
					}
				}, {
					text: '三个月后',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setMonth(date.getMonth() + 3);
						picker.$emit('pick', date);
					}
				}]
			},
			pickerOptionsEdit1: {
				disabledDate: (time) => {
					if (this.editForm.due_date) {
						return time.getTime() >= this.editForm.due_date;
					}
				},
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						picker.$emit('pick', date);
					}
				}, {
					text: '昨天',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setTime(date.getTime() - 3600 * 1000 * 24);
						picker.$emit('pick', date);
					}
				}, {
					text: '一周前',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', date);
					}
				}]
			},
			pickerOptionsEdit2: {
				disabledDate: (time) => {
					if (this.editForm.bill_date !== '') {
						return time.getTime() <= this.editForm.bill_date;
					}
				},
				shortcuts: [{
					text: '一周后',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
						picker.$emit('pick', date);
					}
				}, {
					text: '一个月后',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setMonth(date.getMonth() + 1);
						picker.$emit('pick', date);
					}
				}, {
					text: '三个月后',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setMonth(date.getMonth() + 3);
						picker.$emit('pick', date);
					}
				}]
			},
			uploadDialogVisible: false
		};
	},
	computed: {
		...mapState('bill', ['billList', 'billTotal', 'billsInMonth']),
	},
	methods: {
		handleCurrentChange(newPage) {
			this.queryInfo.currentPage = newPage;
			this.getBillInfo();
		},
		handleSizeChange(newSize) {
			this.queryInfo.pageSize = newSize;
			this.getBillInfo();
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getBillInfo();
		},
		formatDate(r, c, v) {
			if (v) return this.$dayjs(v).format('MM-DD-YYYY');
			return v;
		},
		timeChange(value) {
			if (!value) {
				this.queryInfo.date_start = null;
				this.queryInfo.date_end = null;
				this.queryInfo.currentPage = 1;
				this.getBillInfo();
			} else {
				this.queryInfo.date_start = value[0];
				this.queryInfo.date_end = value[1];
			}
		},
		addDialogClosed() {
			this.$refs.addFormRef.resetFields();
		},
		addBill() {
			this.$refs.addFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.post(`properties/billinfo/${this.pid}`, this.addForm);
				if (res.meta.status !== 201) return this.$message.error('添加账单失败！');
				this.$message.success('添加账单成功！');
				this.addDialogVisible = false;
				this.getBillInfo();
			});
		},
		showEditDialog(currrentBill) {
			this.editForm = {...currrentBill};
			this.editDialogVisible = true;
		},
		editDialogClosed() {
			this.$refs.editFormRef.resetFields();
		},
		editBill() {
			this.$refs.editFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.put(`properties/billinfo/${this.editForm.id}`, this.editForm);
				if (res.meta.status !== 200) return this.$message.error('修改账单失败！');
				this.$message.success('修改账单成功！');
				this.editDialogVisible = false;
				this.getBillInfo();
			});
		},
		async removeBillById(bid) {
			const confirmResult = await this.$confirm('此操作将永久删除该账单, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).catch(err => err);

			// 如果用户确认删除， 则返回值为字符串 confirm
			// 如果用户取消了删除，则返回值为字符串 cancel
			if (confirmResult !== 'confirm') {
				return this.$message.info('已取消删除！');
			}
			const {data: delFileRes} = await this.$http.delete(`billdelete/${bid}`);
			if (delFileRes.meta.status !== 200) {
				return this.$message.error('账单删除失败!');
			}
			const {data: res} = await this.$http.delete(`properties/billinfo/${bid}`);
			if (res.meta.status !== 200) {
				return this.$message.error('账单删除失败!');
			}
			this.$message.success('账单已成功删除！');
			this.getBillInfo();
		},
		validateOverDue(billInfo) {
			if (this.$dayjs(billInfo.due_date) < this.$dayjs()) {
				return true;
			} else {
				return false;
			}
		},
		showUploadDialog(currentBill) {
			this.uploadForm = {...currentBill};
			this.uploadDialogVisible = true;
		},
		fileChange(file, fileList) {
			const isPdf = file.raw.type === 'application/pdf';
			const isLt2M = file.size / 1024 / 1024 < 1;
			if (!isPdf) {
				this.$message.error('上传文件只能是PDF格式!');
				this.fileList = fileList.filter(f => f.uid !== file.uid);
				return false;
			}
			if (!isLt2M) {
				this.$message.error('上传文件大小不能超过1MB!');
				this.fileList = fileList.filter(f => f.uid !== file.uid);
				return false;
			}
			this.fileList = fileList;
		},
		handleRemove(file, fileList) {
			this.fileList = fileList;
		},
		handleExceed(files, fileList) {
			this.$message.warning('当前限制选择1个文件!');
		},
		uploadFile() {
			this.uploadLoading = true;
			this.$refs.uploadFormRef.validate(async valid => {
				if (!valid) {
					this.uploadLoading = false;
					return;
				}
				if (this.fileList.length === 0) {
					this.uploadLoading = false;
					this.$message.error('没有选取任何文件！');
					return false;
				}
				let formData = new FormData();
				this.fileList.forEach(file => {
					formData.append('file', file.raw);
				});
				formData.append('billId', this.uploadForm.id);
				formData.append('propId', this.uploadForm.prop_cont);
				const {data: res} = await this.$http.post('billupload', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
				if (res.meta.status !== 200) {
					this.uploadLoading = false;
					return this.$message.error('文件上传失败！');
				}
				this.$message.success('文件上传成功！');
				this.uploadDialogClosed();
			});
		},
		uploadDialogClosed() {
			this.uploadLoading = false;
			this.fileList.length = 0;
			this.$refs.upload.clearFiles();
			this.uploadDialogVisible = false;
			this.getBillInfo();
		},
		async deletePdfFile(bid) {
			const confirmResult = await this.$confirm('此操作将删除上传的PDF文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).catch(err => err);
			if (confirmResult !== 'confirm') {
				return this.$message.info('已取消删除！');
			}
			const {data: delFileRes} = await this.$http.delete(`billdelete/${bid}`);
			if (delFileRes.meta.status !== 200) {
				return this.$message.error('文件删除失败!');
			}
			this.$message.success('文件已成功删除！');
			this.getBillInfo();
		},
	}
}
</script>

<style lang="less" scoped>
.title1 {
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title2 {
	text-align: center;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	margin-bottom: 20px;
}
.el-col.header-last {
	text-align: right;
}
.el-table {
	margin-top: 20px;
}
.el-select {
	width: 100%;
}
</style>