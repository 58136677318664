<template>
	<div>
		<h1>Welcome, {{username}}</h1>
	</div>
</template>

<script>
export default {
	name: 'Welcome',
	computed: {
		username() {
			return window.sessionStorage.getItem('username');
		}
	}
}
</script>

<style>
</style>