<template>
	<div>
		<IncomeFunction :pid="pid" :showType="showType" :queryInfo="queryInfo"
			:getIncomeList="getIncomeList">
			<template slot-scope="{total, handleCurrentChange, handleSizeChange, queryInfo}">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryInfo.currentPage" :page-sizes="[10, 20, 50, 100]"
					:page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</template>
		</IncomeFunction>
	</div>
</template>

<script>
import IncomeFunction from '@/components/PropertyTab/utilities/IncomeFunction.vue';
export default {
	name: 'IncomeTab',
	props: ['pid', 'getIncomeList', 'queryInfo'],
	components: {IncomeFunction},
	data() {
		return {
			showType: 2,
		};
	}
}
</script>