import Vue from 'vue';
import Vuex from 'vuex';
import prop from './prop';
import lease from './lease';
import bill from './bill';
import check from './check';
import income from './income';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    prop,
    lease,
    bill,
    check,
    income
  }
});