export default {
  data() {
    const validateDateRange = (rule, value, callback) => {
      if (!value || value.length === 0) {
        callback(new Error('请选择日期！'));
      } else {
        callback();
      }
    };
    const validateNumber = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('请输入数值！'));
      } else {
        callback();
      }
    };
    return {
      editDialogVisible: false,
      editForm: {
        dateRange: []
      },
      editFormRules: {
        dateRange: [
          {validator: validateDateRange, trigger: 'blur'}
        ],
        rent_per_month: [
          {required: true, message: '请输入月租', trigger: 'blur'},
          {validator: validateNumber, trigger: 'blur'}
        ],
        mgmt_fee: [
          {required: true, message: '请输入管理费', trigger: 'blur'},
          {validator: validateNumber, trigger: 'blur'}
        ],
      },
      addLeaseDialog: false,
      addLeaseForm: {
        dateRange: [],
        rent_per_month: null,
        deposit: null,
        pet_deposit: null,
        mgmt_fee_rate: 0.07,
        note: ''
      },
      addLeaseFormRules: {
        dateRange: [
          {validator: validateDateRange, trigger: 'blur'}
        ],
        rent_per_month: [
          {required: true, message: '请输入月租', trigger: 'blur'},
          {validator: validateNumber, trigger: 'blur'}
        ],
        mgmt_fee: [
          {required: true, message: '请输入管理费', trigger: 'blur'},
          {validator: validateNumber, trigger: 'blur'}
        ],
      }
    };
  },
  methods: {
    openEditDialog(leaseInfo) {
      const tempObject = {
        id: leaseInfo.id,
        rent_per_month: leaseInfo.rent_per_month,
        deposit: leaseInfo.deposit,
        pet_deposit: leaseInfo.pet_deposit,
        mgmt_fee: leaseInfo.mgmt_fee,
        note: leaseInfo.note
      };
      this.editForm.dateRange.length = 0;
      this.editForm.dateRange.push(leaseInfo.date_start);
      this.editForm.dateRange.push(leaseInfo.date_end);

      this.editForm = {...this.editForm, ...tempObject};
      this.editDialogVisible = true;
    },
    editInfoUpdate() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return;
        const {data: res} = await this.$http.put('properties/leaseinfo/' + this.editForm.id, this.editForm);
        if (res.meta.status !== 200) return this.$message.error('更新租约失败！');
        this.$message.success('更新租约成功！');
        this.editDialogVisible = false;
        this.getLeaseInfo();
      });
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    addLeaseDialogClosed() {
      this.$refs.addLeaseFormRef.resetFields();
    },
    addLease() {
      this.$refs.addLeaseFormRef.validate(async valid => {
        if (!valid) return;
        const {data: res} = await this.$http.post('properties/leaseinfo/' + this.pid, this.addLeaseForm);
        if (res.meta.status !== 201) return this.$message.error('添加租约失败！');
        this.$message.success('添加租约成功！');
        this.addLeaseDialog = false;
        this.getLeaseInfo();
      });
    }
  },
};
