import vue from '../main';
export default {
  namespaced: true,
  actions: {
    async getBillsInMonth(context, pid) {
      const {data: res} = await vue.$http.get(`properties/billsinmonth/${pid}`);
      if (res.meta.status !== 200) return vue.$message.error('获取月内账单列表失败！');
      context.commit('SAVE_BILLINMONTH', {list: res.data, total: res.data.length});
    }
  },
  mutations: {
    SAVE_BILLLIST(state, value) {
      state.billList = value;
    },
    SAVE_BILLTOTAL(state, value) {
      state.billTotal = value;
    },
    SAVE_BILLINMONTH(state, value) {
      state.billsInMonth = value;
    }
  },
  state: {
    billList: [],
    billTotal: 0,
    billsInMonth: {}
  },
  getters: {}
};