<template>
	<div class="tenants-warp">
		<p class="tenants-desc">当前租客</p>
		<el-table class="tenants-wrapper" v-if="leaseInfo && Object.keys(leaseInfo).length > 0"
			:data="leaseInfo.tenants" style="width: 100%" border>
			<el-table-column type="index"></el-table-column>
			<el-table-column prop="name" label="姓名" align="center">
			</el-table-column>
			<el-table-column prop="email" label="邮件" align="center">
			</el-table-column>
			<el-table-column prop="mobile" label="电话" align="center">
			</el-table-column>
			<el-table-column align="right">
				<template slot="header">
					<el-button type="warning" size="small" @click="addTenantDialogVisible = true">添加租客
					</el-button>
				</template>
				<template slot-scope="scope">
					<el-tooltip effect="dark" content="修改" placement="top" :enterable="false">
						<el-button type="primary" icon="el-icon-edit" size="mini"
							@click="showEditTenantDialog(scope.row.id)">
						</el-button>
					</el-tooltip>
					<el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
						<el-button type="danger" icon="el-icon-delete" size="mini"
							@click="deleteTenant(scope.row.id)">
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog title="添加租户" :visible.sync="addTenantDialogVisible" width="50%"
			@close="addTenantDialogClosed">
			<el-form :model="addTenantForm" :rules="addTenantFormRules" ref="addTenantFormRef"
				label-width="100px">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="addTenantForm.name" style="width: 90%;"></el-input>
				</el-form-item>
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="addTenantForm.email" style="width: 90%;"></el-input>
				</el-form-item>
				<el-form-item label="电话" prop="mobile">
					<el-input v-model="addTenantForm.mobile" style="width: 90%;"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addTenantDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addTenant">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="修改信息" :visible.sync="editTenantDialogVisible" width="50%"
			@close="editTenantDialogClosed">
			<el-form :model="editTenantForm" :rules="editTenantFormRules" ref="editTenantFormRef"
				label-width="100px">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="editTenantForm.name" style="width: 90%;"></el-input>
				</el-form-item>
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="editTenantForm.email" style="width: 90%;"></el-input>
				</el-form-item>
				<el-form-item label="电话" prop="mobile">
					<el-input v-model="editTenantForm.mobile" style="width: 90%;"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editTenantDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editTenant">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
export default {
	name: 'TenantFunction',
	props: ['leaseInfo', 'getLeaseInfo'],
	data() {
		return {
			addTenantDialogVisible: false,
			addTenantForm: {
				name: '',
				email: '',
				mobile: ''
			},
			addTenantFormRules: {
				name: [
					{ required: true, message: '姓名必须输入！', trigger: 'blur' }
				],
				email: [
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
				]
			},
			editTenantDialogVisible: false,
			editTenantForm: {},
			editTenantFormRules: {
				name: [
					{ required: true, message: '姓名必须输入！', trigger: 'blur' }
				],
				email: [
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
				]
			},
		};
	},
	methods: {
		addTenantDialogClosed() {
			this.$refs.addTenantFormRef.resetFields();
		},
		addTenant() {
			this.$refs.addTenantFormRef.validate(async valid => {
				if (!valid) return;
				const { data: res } = await this.$http.post(`properties/leaseinfo/tenant/${this.leaseInfo.id}`, this.addTenantForm);
				if (res.meta.status !== 201) return this.$message.error('添加租户失败！');
				this.$message.success('添加租户成功！');
				this.addTenantDialogVisible = false;
				this.getLeaseInfo();
			});
		},
		editTenantDialogClosed() {
			this.$refs.editTenantFormRef.resetFields();
		},
		showEditTenantDialog(tid) {
			const tenant = this.leaseInfo.tenants.filter(t => t.id === tid);
			this.editTenantForm = { ...tenant[0] };
			this.editTenantDialogVisible = true;
		},
		editTenant() {
			this.$refs.editTenantFormRef.validate(async valid => {
				if (!valid) return;
				const { data: res } = await this.$http.put(`properties/leaseinfo/${this.leaseInfo.id}/tenant/${this.editTenantForm.id}`, this.editTenantForm);
				if (res.meta.status !== 200) return this.$message.error('修改信息失败！');
				this.$message.success('修改信息成功！');
				this.editTenantDialogVisible = false;
				this.getLeaseInfo();
			});
		},
		async deleteTenant(tid) {
			const confirmResult = await this.$confirm('此操作将永久删除该租户, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).catch(err => err);

			// 如果用户确认删除， 则返回值为字符串 confirm
			// 如果用户取消了删除，则返回值为字符串 cancel
			if (confirmResult !== 'confirm') {
				return this.$message.info('已取消删除！');
			}

			const { data: res } = await this.$http.delete(`properties/leaseinfo/${this.leaseInfo.id}/tenant/${tid}`);
			if (res.meta.status !== 200) {
				return this.$message.error('租客删除失败!');
			}
			this.$message.success('租客已成功删除！');
			this.getLeaseInfo();
		},
	}
}
</script>

<style lang="less" scoped>
.tenants-warp {
	padding: 0 40px;
	.tenants-desc {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 10px;
		text-align: center;
	}
	.el-table.tenants-wrapper {
		margin-top: 0;
	}
}
</style>