<template>
	<div>
		<CheckFunction :pid="pid" :showType="showType" :queryInfo="queryInfo"
			:getCheckList="getCheckList">
			<template slot-scope="{total, handleCurrentChange, queryInfo}">
				<el-pagination @current-change="handleCurrentChange"
					:current-page.sync="queryInfo.currentPage" :page-size="queryInfo.pageSize"
					layout="total, prev, pager, next" :total="total">
				</el-pagination>
			</template>
		</CheckFunction>
	</div>
</template>

<script>
import CheckFunction from '@/components/PropertyTab/utilities/CheckFunction.vue';
export default {
	name: 'CheckInfo',
	props: ['pid', 'getCheckList', 'queryInfo'],
	components: { CheckFunction },
	data() {
		return {
			showType: 1,
		};
	}
}
</script>