import Vue from 'vue';
import VueRouter from 'vue-router';

const Login = () => import('@/views/login');
const Home = () => import('@/views/home');
const Welcome = () => import('@/views/home/welcome');
const Users = () => import('@/views/home/users');
const Clients = () => import('@/views/home/clients');
const ClientInvoices = () => import('@/views/home/clientInvoices');
const Properties = () => import('@/views/home/properties');
const Detail = () => import('@/views/home/properties/detail');
const PageNotFound = () => import('@/views/pagenotfound');

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/home',
      component: Home,
      redirect: '/welcome',
      children: [
        {
          path: '/welcome',
          component: Welcome
        },
        {
          path: '/users',
          component: Users
        },
        {
          path: '/clients',
          component: Clients
        },
        {
          path: '/clients/invoices/:cid',
          component: ClientInvoices,
          props: true
        },
        {
          path: '/properties',
          component: Properties
        },
        {
          path: '/properties/detail/:pid',
          component: Detail,
          props: true
        }
      ]
    },
    {
      path: '*',
      component: PageNotFound
    }
  ]
});

router.beforeEach((to, _, next) => {
  if (to.path === '/login') return next();
  const tokenStr = window.sessionStorage.getItem('token');
  if (!tokenStr) return next('/login');
  next();
});

export default router;