<template>
	<div>
		<div v-if="showType === 1" class="title1">
			<span>5. 六个月内支付支票预览</span>
			<el-button type="primary" @click="addDialogVisible = true" size="small">添加支票</el-button>
		</div>

		<div v-if="showType === 2">
			<div class="title2">
				<span>支票列表，默认显示所有支票</span>
			</div>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-date-picker v-model="dateRange" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels style="width: 100%"
						@change="timeChange">
					</el-date-picker>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" @click="getCheckList" icon="el-icon-search">范围搜索</el-button>
				</el-col>
				<el-col :span="12" class="header-last">
					<el-button type="warning" @click="addDialogVisible = true">添加支票</el-button>
				</el-col>
			</el-row>
		</div>

		<el-table :data="showType === 2 ? checkList : checksInSix.list" style="width: 100%" border
			@sort-change="changeSort">
			<el-table-column type="index"></el-table-column>
			<el-table-column prop="sent_date" label="签发日期" :formatter="formatDate" min-width="120"
				sortable="custom">
			</el-table-column>
			<el-table-column prop="check_amount" label="支票金额" min-width="100">
			</el-table-column>
			<el-table-column prop="check_no" label="支票号码" min-width="100">
			</el-table-column>
			<el-table-column prop="note" label="备注" min-width="400">
			</el-table-column>
			<el-table-column prop="is_sent" label="是否寄出" width="120" fixed="right" sortable="custom">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.is_sent" type="success" size="medium">已寄出</el-tag>
					<el-tag v-else type="danger" size="medium">未寄出</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="120" fixed="right">
				<template slot-scope="scope">
					<el-tooltip effect="dark" content="修改" placement="top" :enterable="false">
						<el-button type="primary" icon="el-icon-edit" size="mini"
							@click="showEditDialog(scope.row)">
						</el-button>
					</el-tooltip>
					<el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
						<el-button type="danger" icon="el-icon-delete" size="mini"
							@click="removeCheckById(scope.row.id)">
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>

		<slot :total="showType === 2 ? checkTotal : checksInSix.total"
			:handleCurrentChange="handleCurrentChange" :handleSizeChange="handleSizeChange"
			:queryInfo="queryInfo"></slot>

		<el-dialog title="添加支票" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
			<el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="签发日期" prop="sent_date">
							<el-date-picker v-model="addForm.sent_date" align="left" type="date"
								placeholder="选择日期" :picker-options="pickerOptions" format="MM-dd-yyyy"
								style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="金额" prop="check_amount">
							<el-input v-model="addForm.check_amount" style="width: 100%;"
								placeholder="输入数字，不要带符号！">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="支票号码" prop="check_no">
							<el-input v-model="addForm.check_no"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="是否寄出" prop="is_sent">
							<el-switch v-model="addForm.is_sent" disabled>
							</el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" placeholder="请输入内容" v-model="addForm.note" maxlength="150"
						show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 90%;">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addCheck">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="修改支票" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
			<el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="签发日期" prop="sent_date">
							<el-date-picker v-model="editForm.sent_date" align="left" type="date"
								placeholder="选择日期" :picker-options="pickerOptions" format="MM-dd-yyyy"
								style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="金额" prop="check_amount">
							<el-input v-model="editForm.check_amount" style="width: 100%;"
								placeholder="输入数字，不要带符号！">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="支票号码" prop="check_no">
							<el-input v-model="editForm.check_no"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="是否寄出" prop="is_sent">
							<el-switch v-model="editForm.is_sent">
							</el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" placeholder="请输入内容" v-model="editForm.note" maxlength="150"
						show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 90%;">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editCheck">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
	name: 'CheckFunction',
	props: ['pid', 'showType', 'queryInfo', 'getCheckList'],
	data() {
		const validateNumber = (rule, value, callback) => {
			if (isNaN(value)) {
				callback(new Error('请输入数值！'));
			} else {
				callback();
			}
		};
		return {
			dateRange: [],
			addDialogVisible: false,
			addForm: {
				sent_date: '',
				is_sent: false,
				check_no: '',
				check_amount: '',
				note: ''
			},
			addFormRules: {
				sent_date: [
					{required: true, message: '时间必须输入！', trigger: 'blur'}
				],
				check_amount: [
					{required: true, message: '请输入金额！', trigger: 'blur'},
					{validator: validateNumber, trigger: 'blur'}
				]
			},
			pickerOptions: {
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						picker.$emit('pick', date);
					}
				}, {
					text: '昨天',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setTime(date.getTime() - 3600 * 1000 * 24);
						picker.$emit('pick', date);
					}
				}, {
					text: '一周前',
					onClick(picker) {
						const date = new Date();
						date.setHours(0, 0, 0, 0);
						date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', date);
					}
				}]
			},
			editDialogVisible: false,
			editForm: {},
			editFormRules: {
				sent_date: [
					{required: true, message: '时间必须输入！', trigger: 'blur'}
				],
				check_amount: [
					{required: true, message: '请输入金额！', trigger: 'blur'},
					{validator: validateNumber, trigger: 'blur'}
				]
			}
		};
	},
	computed: {
		...mapState('check', ['checkList', 'checkTotal', 'checksInSix']),
	},
	methods: {
		handleCurrentChange(newPage) {
			this.queryInfo.currentPage = newPage;
			this.getCheckList();
		},
		handleSizeChange(newSize) {
			this.queryInfo.pageSize = newSize;
			this.getCheckList();
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getCheckList();
		},
		formatDate(r, c, v) {
			if (v) return this.$dayjs(v).format('MM-DD-YYYY');
			return v;
		},
		timeChange(value) {
			if (!value) {
				this.queryInfo.date_start = null;
				this.queryInfo.date_end = null;
				this.queryInfo.currentPage = 1;
				this.getCheckList();
			} else {
				this.queryInfo.date_start = value[0];
				this.queryInfo.date_end = value[1];
			}
		},
		addDialogClosed() {
			this.$refs.addFormRef.resetFields();
		},
		addCheck() {
			this.$refs.addFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.post(`properties/checkinfo/${this.pid}`, this.addForm);
				if (res.meta.status !== 201) return this.$message.error('添加支票失败！');
				this.$message.success('添加支票成功！');
				this.addDialogVisible = false;
				this.getCheckList();
			});
		},
		showEditDialog(currentCheck) {
			this.editForm = {...currentCheck};
			this.editDialogVisible = true;
		},
		editDialogClosed() {
			this.$refs.editFormRef.resetFields();
		},
		editCheck() {
			this.$refs.editFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.put(`properties/checkinfo/${this.editForm.id}`, this.editForm);
				if (res.meta.status !== 200) return this.$message.error('修改支票失败！');
				this.$message.success('修改支票成功！');
				this.editDialogVisible = false;
				this.getCheckList();
			});
		},
		async removeCheckById(cid) {
			const confirmResult = await this.$confirm('此操作将永久删除该支票, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).catch(err => err);

			// 如果用户确认删除， 则返回值为字符串 confirm
			// 如果用户取消了删除，则返回值为字符串 cancel
			if (confirmResult !== 'confirm') {
				return this.$message.info('已取消删除！');
			}

			const {data: res} = await this.$http.delete(`properties/checkinfo/${cid}`);
			if (res.meta.status !== 200) {
				return this.$message.error('支票删除失败!');
			}
			this.$message.success('支票已成功删除！');
			this.getCheckList();
		}
	}
}
</script>

<style lang="less" scoped>
.title1 {
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title2 {
	text-align: center;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	margin-bottom: 20px;
}
.el-col.header-last {
	text-align: right;
}
.el-table {
	margin-top: 20px;
}
</style>