<template>
	<el-container class="home-container">
		<el-header>
			<div class="content-wrap">
				<img src="@/assets/img/gigilogo.png" alt="">
				<span>休斯顿好房网房产管理系统</span>
			</div>
			<el-button type="info" @click="logout">退出</el-button>
		</el-header>
		<el-container>
			<el-aside :width="isCollapse? '64px' : '200px'">
				<div class="toggle-button" @click="toggleCollapse">{{indicator}}</div>
				<el-menu background-color="#333744" text-color="#fff" active-text-color="#409BFF"
					unique-opened :collapse="isCollapse" :collapse-transition="false" router
					:default-active="activePath">
					<el-submenu :index="item.id + ''" v-for="item in menulist" :key="item.id">
						<template slot="title">
							<i :class="iconsObj[item.id]"></i>
							<span>{{item.authName}}</span>
						</template>
						<el-menu-item :index="subitem.path" v-for="subitem in item.children" :key="subitem.id">
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span>{{subitem.authName}}</span>
							</template>
						</el-menu-item>
					</el-submenu>
				</el-menu>
			</el-aside>
			<el-main :style="mainStyle">
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
			indicator: '◀◀',
			menulist: [],
			iconsObj: {
				'61d3eb5ab2dd56c2d95dc357': 'iconfont icon-icon-users',
				'61d3eb5ab2dd56c2d95dc353': 'iconfont icon-house',
				'61d3eb5ab2dd56c2d95dc35b': 'iconfont icon-icon_user'
			},
			isCollapse: false,
			activePath: '',
			mainStyle: {
				left: '200px'
			}
		};
	},
	methods: {
		logout() {
			window.sessionStorage.clear();
			this.$router.push('/login');
		},
		async getMenuList() {
			const { data: res } = await this.$http.get('menus');
			if (res.meta.status !== 200) return this.$message.error(res.meta.msg);
			this.menulist = res.data;
		},
		toggleCollapse() {
			this.isCollapse = !this.isCollapse;
			if (this.isCollapse) {
				this.mainStyle.left = '64px';
				this.indicator = '▶▶';
			} else {
				this.mainStyle.left = '200px';
				this.indicator = '◀◀';
			}
		},
		saveNavState(activePath) {
			window.sessionStorage.setItem('activePath', activePath);
			this.activePath = activePath;
		}
	},
	created() {
		this.getMenuList();
		this.activePath = window.sessionStorage.getItem('activePath');
	},
	watch: {
		'$route'(to) {
			const pathArr = to.path.split('/');
			this.saveNavState('/' + pathArr[1]);
		}
	}
}
</script>

<style lang="less" scoped>
.home-container {
	position: relative;
	height: 100%;
	.el-header {
		position: relative;
		background-color: #373d41;
		display: flex;
		justify-content: space-between;
		padding-left: 0;
		align-items: center;
		color: #fff;
		font-size: 20px;
		.content-wrap {
			display: flex;
			align-items: center;
			img {
				height: 60px;
			}
			span {
				margin-left: 15px;
			}
		}
	}
	.el-aside {
		position: absolute;
		height: 100%;
		background-color: #333744;
		.toggle-button {
			background-color: #4a5064;
			font-size: 12px;
			line-height: 24px;
			color: #fff;
			text-align: center;
			letter-spacing: 0.2em;
			cursor: pointer;
		}
		.el-menu {
			border-right: none;
		}
	}
	.el-main {
		position: absolute;
		left: 200px;
		right: 0;
		top: 60px;
		bottom: 0;
		overflow-y: scroll;
		background-color: #eaedf1;
	}
	.iconfont {
		margin-right: 10px;
	}
}
</style>