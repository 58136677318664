<template>
	<div>
		<div class="lease-wrapper" v-if="leaseInfo && Object.keys(leaseInfo).length > 0">
			<el-descriptions title="2. 最快过期的有效租约" :column="2" border :labelStyle="{'width': '120px'}">
				<template slot="extra">
					<el-button type="primary" size="small" @click="openEditDialog(leaseInfo)">修改租约</el-button>
				</template>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-qishishijian"></i>
						起始日期
					</template>
					{{getFormattedTime(leaseInfo.date_start)}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-zhongzhishijian"></i>
						终止日期
					</template>
					{{getFormattedTime(leaseInfo.date_end)}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-yuezucheweifei"></i>
						月租
					</template>
					{{leaseInfo.rent_per_month}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-feishuaiguanli"></i>
						管理费
					</template>
					{{leaseInfo.mgmt_fee}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-yajin"></i>
						押金
					</template>
					{{leaseInfo.deposit ? leaseInfo.deposit : ''}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-chongwu"></i>
						宠物押金
					</template>
					{{leaseInfo.pet_deposit ? leaseInfo.pet_deposit : ''}}
				</el-descriptions-item>
				<el-descriptions-item :span="2">
					<template slot="label">
						<i class="iconfont icon-beizhu"></i>
						备注
					</template>
					{{leaseInfo.note}}
				</el-descriptions-item>
			</el-descriptions>

			<TenantFunction :leaseInfo="leaseInfo" :getLeaseInfo="getLeaseInfo" />

		</div>

		<el-descriptions v-else title="2. 当前房产没有有效租约">
			<template slot="extra">
				<el-button type="primary" size="small" @click="addLeaseDialog = true">添加租约</el-button>
			</template>
		</el-descriptions>

		<el-dialog title="添加租约" :visible.sync="addLeaseDialog" width="50%"
			@close="addLeaseDialogClosed">
			<el-form :model="addLeaseForm" :rules="addLeaseFormRules" ref="addLeaseFormRef"
				label-width="100px">
				<el-form-item label="选择租期" prop="dateRange">
					<el-date-picker v-model="addLeaseForm.dateRange" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" clearable format="MM-dd-yyyy"
						style="width: 90%;" unlink-panels>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="月租" prop="rent_per_month">
					<el-input v-model="addLeaseForm.rent_per_month" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="押金" prop="deposit">
					<el-input v-model="addLeaseForm.deposit" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="宠物押金" prop="pet_deposit">
					<el-input v-model="addLeaseForm.pet_deposit" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="管理费" prop="mgmt_fee">
					<el-input v-model="addLeaseForm.mgmt_fee" style="width: 90%;" placeholder="纯数字">
					</el-input>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" placeholder="请输入内容" v-model="addLeaseForm.note" maxlength="150"
						show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 90%;">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addLeaseDialog = false">取 消</el-button>
				<el-button type="primary" @click="addLease">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="修改当前租约" :visible.sync="editDialogVisible" width="50%"
			@close="editDialogClosed">
			<el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
				<el-form-item label="选择租期" prop="dateRange">
					<el-date-picker v-model="editForm.dateRange" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" clearable format="MM-dd-yyyy"
						style="width: 90%;" unlink-panels>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="月租" prop="rent_per_month">
					<el-input v-model="editForm.rent_per_month" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="押金" prop="deposit">
					<el-input v-model="editForm.deposit" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="宠物押金" prop="pet_deposit">
					<el-input v-model="editForm.pet_deposit" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="管理费" prop="mgmt_fee">
					<el-input v-model="editForm.mgmt_fee" style="width: 90%;" placeholder="纯数字">
					</el-input>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" placeholder="请输入内容" v-model="editForm.note" maxlength="150"
						show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 90%;">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editInfoUpdate">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
import {mapState} from 'vuex';
import TenantFunction from '@/components/PropertyTab/utilities/TenantFunction';
import leaseMixin from '@/components/PropertyTab/utilities/leaseMixin.js';
export default {
	name: 'LeaseInfo',
	props: ['pid', 'getLeaseInfo', 'queryInfo'],
	components: {TenantFunction},
	computed: {
		...mapState('lease', ['leaseInfo'])
	},
	mixins: [leaseMixin],
	methods: {
		getFormattedTime(time) {
			return this.$dayjs(time).format('MMMM D, YYYY');
		}
	}
}

</script>

<style lang="less" scoped>
</style>