<template>
	<div>
		<CheckFunction :pid="pid" :showType="showType" :queryInfo="queryInfo"
			:getCheckList="getCheckList">
			<template slot-scope="{total, handleCurrentChange, handleSizeChange, queryInfo}">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryInfo.currentPage" :page-sizes="[10, 20, 50, 100]"
					:page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</template>
		</CheckFunction>
	</div>
</template>

<script>
import CheckFunction from '@/components/PropertyTab/utilities/CheckFunction.vue';
export default {
	name: 'CheckTab',
	props: ['pid', 'getCheckList', 'queryInfo'],
	components: { CheckFunction },
	data() {
		return {
			showType: 2,
		};
	}
}
</script>