<template>
	<div>
		<IncomeFunction :pid="pid" :showType="showType" :queryInfo="queryInfo"
			:getIncomeList="getIncomeList">
			<template slot-scope="{total, handleCurrentChange, queryInfo}">
				<el-pagination @current-change="handleCurrentChange"
					:current-page.sync="queryInfo.currentPage" :page-size="queryInfo.pageSize"
					layout="total, prev, pager, next" :total="total">
				</el-pagination>
			</template>
		</IncomeFunction>
	</div>
</template>

<script>
import IncomeFunction from '@/components/PropertyTab/utilities/IncomeFunction.vue';
export default {
	name: 'IncomeInfo',
	props: ['pid', 'getIncomeList', 'queryInfo'],
	components: {IncomeFunction},
	data() {
		return {
			showType: 1,
		};
	}
}
</script>