<template>
	<div>
		<el-descriptions v-if="propertyBaseInfo && Object.keys(propertyBaseInfo).length > 0"
			title="1. 基本信息" :column="2" border :labelStyle="{'width': '120px'}">
			<template slot="extra">
				<el-button type="primary" size="small" @click="openEditPropertyDialog">修改信息</el-button>
			</template>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-person"></i>
					房东
				</template>
				{{propertyBaseInfo.owner.username}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-dizhi"></i>
					地址
				</template>
				{{propertyBaseInfo.address}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-dishui"></i>
					地税付款
				</template>
				<el-tag v-if="propertyBaseInfo.self_paid" type="primary" size="medium">自付</el-tag>
				<el-tag v-else type="warning" size="medium">非自付</el-tag>
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-zulindanwei-xian"></i>
					租赁情况
				</template>
				<el-tag v-if="propertyBaseInfo.is_leasing" type="success" size="medium">出租中</el-tag>
				<el-tag v-else type="danger" size="medium">未租</el-tag>
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-yue"></i>
					周期余额
				</template>
				{{propertyBaseInfo.balance}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-beizhu"></i>
					备注
				</template>
				{{propertyBaseInfo.note}}
			</el-descriptions-item>
		</el-descriptions>

		<el-dialog title="修改房产信息" :visible.sync="editDialogVisible" width="50%"
			@close="editDialogClosed">
			<el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
				<el-form-item label="房东" prop="ownername">
					<el-input v-model="editForm.ownername" disabled style="width: 90%;"></el-input>
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input v-model="editForm.address" style="width: 90%;"></el-input>
				</el-form-item>
				<el-row>
					<el-col :span="11">
						<el-form-item label="自付地税" prop="self_paid">
							<el-switch v-model="editForm.self_paid"></el-switch>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="是否在租" prop="is_leasing">
							<el-switch v-model="editForm.is_leasing"></el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="周期余额" prop="balance">
					<el-input v-model="editForm.balance" style="width: 90%;"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" placeholder="请输入内容" v-model="editForm.note" maxlength="150"
						show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 90%;">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editPropertyInfo">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
	name: 'BaseInfo',
	props: ['pid', 'getBaseinfo'],
	data() {
		return {
			editDialogVisible: false,
			editForm: {},
			editFormRules: {
				address: [
					{required: true, message: '请输入地址', trigger: 'blur'}
				]
			}
		};
	},
	computed: {
		...mapState('prop', ['propertyBaseInfo']),
	},
	methods: {
		openEditPropertyDialog() {
			const tempObject = {
				address: this.propertyBaseInfo.address,
				ownername: this.propertyBaseInfo.owner.username,
				self_paid: this.propertyBaseInfo.self_paid,
				is_leasing: this.propertyBaseInfo.is_leasing,
				balance: this.propertyBaseInfo.balance,
				note: this.propertyBaseInfo.note
			};
			this.editForm = {...tempObject};
			this.editDialogVisible = true;
		},
		editDialogClosed() {
			this.$refs.editFormRef.resetFields();
		},
		editPropertyInfo() {
			this.$refs.editFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.put('properties/baseinfo/' + this.propertyBaseInfo.id, this.editForm);
				if (res.meta.status !== 200) {
					if (res.meta.status === 422) {
						return this.$message.error(res.meta.msg);
					} else {
						return this.$message.error('更新房产信息失败！');
					}
				}
				this.$message.success('更新房产信息成功！');
				this.editDialogVisible = false;
				this.getBaseinfo();
			});
		}
	}
}
</script>