<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>房产管理</el-breadcrumb-item>
			<el-breadcrumb-item>房产列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-input placeholder="请输房产地址关键字" v-model="queryInfo.query" clearable
						@clear="handleSearchClear">
						<el-button slot="append" icon="el-icon-search" @click="getPropertyList"></el-button>
					</el-input>
				</el-col>
			</el-row>

			<el-table :data="propertyList" stripe @sort-change="changeSort" row-key="id"
				v-loading="loading">
				<el-table-column type="index"></el-table-column>
				<el-table-column label="房东" prop="owner.username" sortable="custom" width="250">
				</el-table-column>
				<el-table-column label="地址" prop="address" min-width="350" sortable="custom">
					<template slot-scope="scope">
						<router-link :to="`/properties/detail/${scope.row.id}`">{{scope.row.address}}
						</router-link>
					</template>
				</el-table-column>
				<el-table-column label="租金" width="100">
					<template slot-scope="scope">
						{{scope.row.lease_infos.length > 0 ? scope.row.lease_infos[0].rent_per_month : 'N/A'}}
					</template>
				</el-table-column>
				<el-table-column label="周期余额" prop="balance" width="100" sortable="custom">
					<template slot-scope="scope">
						{{isNaN(scope.row.balance) ? 'N/A' : scope.row.balance}}
					</template>
				</el-table-column>
				<el-table-column label="出租情况" prop="is_leasing" width="100" sortable="custom">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.is_leasing" type="success" size="medium">出租中</el-tag>
						<el-tag v-else type="danger" size="medium">未租</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="140">
					<template slot-scope="scope">
						<el-tooltip effect="dark" content="查看房产详细情况" placement="top" :enterable="false">
							<el-button type="primary" size="small" @click="routeToDetail(scope.row.id)">
								详情
							</el-button>
						</el-tooltip>
						<el-tooltip effect="dark" content="删除此房产" placement="top" :enterable="false">
							<el-button type="danger" icon="el-icon-delete" size="mini"
								@click="showDeletePropertyDialog(scope.row)">
							</el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.pagenum" :page-sizes="[10, 20, 50, 100]"
				:page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>

		<el-dialog title="删除房产" :visible.sync="setPropertyDeleteDialogVisible" width="50%"
			@close="setPropertyDeleteDialogClosed">
			<el-alert title="删除房产将会一并删除其名下所有租约，花费等信息！！此房产也会从房东名下移除！！" type="error" effect="dark"
				:closable="false">
			</el-alert>
			<el-form :model="deleteForm" :rules="deleteFormRules" ref="deleteFormRef" label-position="top"
				class="delete-form">
				<el-form-item label="请输入房东全名来确认删除" prop="deletePropertyFromClient">
					<el-input v-model="deleteForm.deletePropertyFromClient"
						placeholder="区分大小写：Firstname Lastname，例如：Joe Foo"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setPropertyDeleteDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="removePropertyById">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'Properties',
	created() {
		this.getPropertyList();
	},
	data() {
		const validateDeleteInput = (rule, value, callback) => {
			if (value !== this.propertyInfo.owner.username) {
				callback(new Error('房东名字输入错误！'));
			} else {
				callback();
			}
		};
		return {
			queryInfo: {
				query: '',
				pagenum: 1,
				pagesize: 10,
				order: '',
				prop: '',
				column: ''
			},
			loading: false,
			propertyList: [],
			total: 0,
			expandRowKeys: [],
			propertyInfo: {},
			setPropertyDeleteDialogVisible: false,
			deleteForm: {
				deletePropertyFromClient: ''
			},
			deleteFormRules: {
				deletePropertyFromClient: [
					{required: true, message: '请输入房东姓名确认', trigger: 'blur'},
					{validator: validateDeleteInput, trigger: 'blur'}
				]
			},
		};
	},
	methods: {
		async getPropertyList() {
			this.loading = true;
			const {data: res} = await this.$http.get('properties', {params: this.queryInfo});
			if (res.meta.status !== 200) return this.$message.error('获取房产列表失败！');
			this.propertyList = res.data.properties;
			this.total = res.data.total;
			this.loading = false;
		},
		handleSizeChange(newSize) {
			this.queryInfo.pagesize = newSize;
			this.getPropertyList();
		},
		handleCurrentChange(newPage) {
			this.queryInfo.pagenum = newPage;
			this.getPropertyList();
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getPropertyList();
		},
		handleSearchClear() {
			this.queryInfo.pagenum = 1;
			this.getPropertyList();
		},
		routeToDetail(id) {
			this.$router.push(`/properties/detail/${id}`);
		},
		showDeletePropertyDialog(propertyInfo) {
			this.propertyInfo = propertyInfo;
			this.setPropertyDeleteDialogVisible = true;
		},
		setPropertyDeleteDialogClosed() {
			this.$refs.deleteFormRef.resetFields();
		},
		removePropertyById() {
			this.$refs.deleteFormRef.validate(async valid => {
				if (!valid) return;

				const confirmResult = await this.$confirm('此操作将永久删除该房产, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).catch(err => err);

				// 如果用户确认删除， 则返回值为字符串 confirm
				// 如果用户取消了删除，则返回值为字符串 cancel
				if (confirmResult !== 'confirm') {
					this.setPropertyDeleteDialogVisible = false;
					return this.$message.info('已取消删除！');
				}
				const {data: res} = await this.$http.delete('properties/' + this.propertyInfo.id);
				if (res.meta.status !== 200) {
					return this.$message.error(res.meta.msg);
				}

				this.$message.success('房产已成功删除！');
				this.getPropertyList();
				this.setPropertyDeleteDialogVisible = false;
			});
		}
	}
}
</script>

<style lang="less" scoped>
.el-descriptions {
	padding: 20px;
}
.owner-slot {
	display: flex;
	justify-content: space-between;
}
a {
	text-decoration: none;
	color: #606266;
}
</style>