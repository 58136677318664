<template>
	<div>
		<div class="title">
			<span>租约列表，默认显示所有租约</span>
		</div>
		<el-row :gutter="20">
			<el-col :span="8">
				<el-date-picker v-model="dateRange" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels style="width: 100%"
					@change="timeChange">
				</el-date-picker>
			</el-col>
			<el-col :span="4">
				<el-button type="primary" @click="getLeaseInfo" icon="el-icon-search">范围搜索</el-button>
			</el-col>
			<el-col :span="12" class="header-last">
				<el-button type="warning" @click="addLeaseDialog = true">添加租约</el-button>
			</el-col>
		</el-row>
		<el-table :data="leaseList" border stripe @sort-change="changeSort" style="width: 100%"
			row-key="id" @expand-change="handleRowExpand" :expand-row-keys="expandRowKeys">
			<el-table-column type="index"></el-table-column>
			<el-table-column type="expand">
				<template slot-scope="scope">
					<TenantFunction :leaseInfo="scope.row" :getLeaseInfo="getLeaseInfo" />
				</template>
			</el-table-column>
			<el-table-column prop="date_start" label="起始日期" sortable="custom" width="120"
				:formatter="formatDate">
			</el-table-column>
			<el-table-column prop="date_end" label="终止日期" sortable="custom" width="120"
				:formatter="formatDate">
			</el-table-column>
			<el-table-column prop="rent_per_month" label="月租" width="80">
			</el-table-column>
			<el-table-column prop="deposit" label="押金" width="80">
			</el-table-column>
			<el-table-column prop="pet_deposit" label="宠物押金" width="80">
			</el-table-column>
			<el-table-column prop="mgmt_fee" label="管理费" width="70">
			</el-table-column>
			<el-table-column prop="note" label="备注" min-width="400">
			</el-table-column>
			<el-table-column label="有效" width="80" sortable="custom">
				<template slot-scope="scope">
					<el-tag v-if="isValidLease(scope.row)" type="success" size="medium">有效</el-tag>
					<el-tag v-else type="danger" size="medium">已过期</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="120">
				<template slot-scope="scope">
					<el-tooltip effect="dark" content="修改" placement="top" :enterable="false">
						<el-button type="primary" icon="el-icon-edit" size="mini"
							@click="openEditDialog(scope.row)">
						</el-button>
					</el-tooltip>
					<el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
						<el-button type="danger" icon="el-icon-delete" size="mini"
							@click="removeLeaseById(scope.row.id)">
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="queryInfo.currentPage" :page-sizes="[10, 20, 50, 100]"
			:page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="leaseTotal">
		</el-pagination>

		<el-dialog title="添加租约" :visible.sync="addLeaseDialog" width="50%"
			@close="addLeaseDialogClosed">
			<el-form :model="addLeaseForm" :rules="addLeaseFormRules" ref="addLeaseFormRef"
				label-width="100px">
				<el-form-item label="选择租期" prop="dateRange">
					<el-date-picker v-model="addLeaseForm.dateRange" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" clearable format="MM-dd-yyyy"
						style="width: 90%;" unlink-panels>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="月租" prop="rent_per_month">
					<el-input v-model="addLeaseForm.rent_per_month" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="押金" prop="deposit">
					<el-input v-model="addLeaseForm.deposit" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="宠物押金" prop="pet_deposit">
					<el-input v-model="addLeaseForm.pet_deposit" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="管理费" prop="mgmt_fee">
					<el-input v-model="addLeaseForm.mgmt_fee" style="width: 90%;" placeholder="输入数字">
					</el-input>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" placeholder="请输入内容" v-model="addLeaseForm.note" maxlength="150"
						show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 90%;">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addLeaseDialog = false">取 消</el-button>
				<el-button type="primary" @click="addLease">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="修改当前租约" :visible.sync="editDialogVisible" width="50%"
			@close="editDialogClosed">
			<el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
				<el-form-item label="选择租期" prop="dateRange">
					<el-date-picker v-model="editForm.dateRange" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" clearable format="MM-dd-yyyy"
						style="width: 90%;" unlink-panels>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="月租" prop="rent_per_month">
					<el-input v-model="editForm.rent_per_month" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="押金" prop="deposit">
					<el-input v-model="editForm.deposit" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="宠物押金" prop="pet_deposit">
					<el-input v-model="editForm.pet_deposit" style="width: 90%;" placeholder="请输入纯数字！">
					</el-input>
				</el-form-item>
				<el-form-item label="管理费" prop="mgmt_fee">
					<el-input v-model="editForm.mgmt_fee" style="width: 90%;" placeholder="输入数字"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" placeholder="请输入内容" v-model="editForm.note" maxlength="150"
						show-word-limit resize="none" :autosize="{ minRows: 3 }" style="width: 90%;">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editInfoUpdate">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {mapState} from 'vuex';
import TenantFunction from '@/components/PropertyTab/utilities/TenantFunction';
import leaseMixin from '@/components/PropertyTab/utilities/leaseMixin.js';
export default {
	name: 'LeaseTab',
	props: ['pid', 'getLeaseInfo', 'queryInfo'],
	components: {TenantFunction},
	data() {
		return {
			dateRange: [],
			expandRowKeys: []
		};
	},
	computed: {
		...mapState('lease', ['leaseList', 'leaseTotal'])
	},
	mixins: [leaseMixin],
	methods: {
		timeChange(value) {
			if (!value) {
				this.queryInfo.date_start = null;
				this.queryInfo.date_end = null;
				this.queryInfo.currentPage = 1;
				this.getLeaseInfo();
			} else {
				this.queryInfo.date_start = value[0];
				this.queryInfo.date_end = value[1];
			}
		},
		formatDate(r, c, v) {
			if (v) return this.$dayjs(v).format('MM-DD-YYYY');
			return v;
		},
		isValidLease(currentLease) {
			const todayDate = this.$dayjs();
			if (this.$dayjs(currentLease.date_start) > todayDate || this.$dayjs(currentLease.date_end) < todayDate) {
				return false;
			} else {
				return true;
			}
		},
		handleSizeChange(newSize) {
			this.queryInfo.pageSize = newSize;
			this.getLeaseInfo();
		},
		handleCurrentChange(newPage) {
			this.queryInfo.currentPage = newPage;
			this.getLeaseInfo();
		},
		handleRowExpand(row) {
			if (this.expandRowKeys.length > 0 && this.expandRowKeys[0] === row.id) {
				this.expandRowKeys.length = 0;
			} else {
				this.expandRowKeys.length = 0;
				this.expandRowKeys.push(row.id);
			}
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getLeaseInfo();
		},
		async removeLeaseById(lid) {
			const confirmResult = await this.$confirm('此操作将永久删除该租约, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).catch(err => err);

			// 如果用户确认删除， 则返回值为字符串 confirm
			// 如果用户取消了删除，则返回值为字符串 cancel
			if (confirmResult !== 'confirm') {
				return this.$message.info('已取消删除！');
			}

			const {data: res} = await this.$http.delete(`properties/leaseinfo/${lid}`);
			if (res.meta.status !== 200) {
				return this.$message.error('租约删除失败!');
			}
			this.$message.success('租约已成功删除！');
			this.getLeaseInfo();
		}

	}
}
</script>

<style lang="less" scoped>
.tenants-warp {
	padding: 0 40px 30px;
}
.title {
	text-align: center;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	margin-bottom: 20px;
}
.el-col.header-last {
	text-align: right;
}
.el-table {
	margin-top: 20px;
}
</style>