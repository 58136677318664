<template>
	<div>
		<BillFunction :pid="pid" :showType="showType" :queryInfo="queryInfo" :getBillInfo="getBillInfo">
			<template slot-scope="{total, handleCurrentChange, queryInfo}">
				<el-pagination @current-change="handleCurrentChange"
					:current-page.sync="queryInfo.currentPage" :page-size="queryInfo.pageSize"
					layout="total, prev, pager, next" :total="total">
				</el-pagination>
			</template>
		</BillFunction>
	</div>
</template>

<script>
import BillFunction from '@/components/PropertyTab/utilities/BillFunction.vue';
export default {
	name: 'BillInfo',
	props: ['pid', 'getBillInfo', 'queryInfo'],
	components: { BillFunction },
	data() {
		return {
			showType: 1,
		};
	},
}
</script>