<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>房东管理</el-breadcrumb-item>
			<el-breadcrumb-item>房东列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-input placeholder="请输房东姓名关键字" v-model="queryInfo.query" clearable
						@clear="handleSearchClear">
						<el-button slot="append" icon="el-icon-search" @click="getClientList"></el-button>
					</el-input>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" @click="addClientDialogVisible = true;">添加房东</el-button>
				</el-col>
			</el-row>

			<el-table :data="clientList" stripe @sort-change="changeSort" row-key="id"
				@expand-change="handleRowExpand" :expand-row-keys="expandRowKeys" v-loading="loading">
				<el-table-column type="index"></el-table-column>
				<el-table-column type="expand">
					<template slot-scope="scope">
						<el-descriptions class="margin-top" title="房东基本信息" :column="3" border
							:labelStyle="{'width': '120px'}">
							<template slot="extra">
								<el-button type="primary" size="medium" @click="showClientEditDialog(scope.row)">
									修改信息</el-button>
							</template>
							<el-descriptions-item :span="2">
								<template slot="label">
									<i class="iconfont icon-person"></i>
									房东姓名
								</template>
								{{scope.row.username}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									<i class="iconfont icon-email"></i>
									邮件地址
								</template>
								{{scope.row.email}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									<i class="iconfont icon-mobile"></i>
									手机号
								</template>
								{{scope.row.mobile}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									<i class="iconfont icon-bank"></i>
									银行名称
								</template>
								{{scope.row.bank.name}}
							</el-descriptions-item>
							<el-descriptions-item>
								<template slot="label">
									<i class="iconfont icon-account"></i>
									银行账户
								</template>
								{{scope.row.bank.account}}
							</el-descriptions-item>
							<el-descriptions-item :span="3">
								<template slot="label">
									<i class="iconfont icon-beizhu"></i>
									房东备注
								</template>
								{{scope.row.note}}
							</el-descriptions-item>
						</el-descriptions>
						<el-descriptions class="margin-top" title="房东房产信息" :column="1" border
							:labelStyle="{'width': '120px'}">
							<template slot="extra">
								<el-button type="primary" size="medium" @click="openAddPropertyDialog(scope.row)">
									添加房产</el-button>
							</template>
							<el-descriptions-item v-for="(property, index) in scope.row.properties"
								:key="property.id">
								<template slot="label">
									<i class="iconfont icon-dizhi"></i>
									{{'房产地址' + (index + 1)}}
								</template>
								<div class="property-slot">
									<router-link :to="`/properties/detail/${property.id}`">{{property.address}}
									</router-link>
									<el-button type="primary" plain size="small" @click="routeToDetail(property.id)">
										查看详情</el-button>
								</div>
							</el-descriptions-item>
						</el-descriptions>
					</template>
				</el-table-column>
				<el-table-column label="姓名" prop="username" sortable="custom">
				</el-table-column>
				<el-table-column label="邮箱" prop="email"></el-table-column>
				<el-table-column label="银行" prop="bank.name" sortable="custom"></el-table-column>
				<el-table-column label="账户" prop="bank.account"></el-table-column>
				<el-table-column label="状态" prop="mg_state" width="65">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.mg_state" @change="clientStateChanged(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="120">
					<template slot-scope="scope">
						<el-tooltip effect="dark" content="删除房东" placement="top" :enterable="false">
							<el-button type="danger" icon="el-icon-delete" size="mini"
								@click="showDeleteClientDialog(scope.row)">
							</el-button>
						</el-tooltip>
						<el-tooltip effect="dark" content="房东Invoice管理" placement="top" :enterable="false">
							<el-button type="success" icon="el-icon-document" size="mini"
								@click="$router.push('/clients/invoices/' + scope.row.id)">
							</el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.pagenum" :page-sizes="[10, 20, 50, 100]"
				:page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>

		<el-dialog title="删除房东" :visible.sync="setClientDeleteDialogVisible" width="50%"
			@close="setClientDeleteDialogClosed">
			<el-alert title="删除房东将会一并删除其名下所有房产及房产租约，花费等信息！！" type="error" effect="dark" :closable="false">
			</el-alert>
			<el-form :model="deleteForm" :rules="deleteFormRules" ref="deleteFormRef" label-position="top"
				class="delete-form">
				<el-form-item label="请输入房东全名来确认删除" prop="deleteClient">
					<el-input v-model="deleteForm.deleteClient"
						placeholder="区分大小写：Firstname Lastname，例如：Joe Foo"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setClientDeleteDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="removeClientById">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="修改房东信息" :visible.sync="setClientEditDialogVisible" width="50%"
			@close="clientEditFormClosed">
			<el-form :model="clientEditForm" :rules="clientEditFormRules" ref="clientEditFormRef"
				label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="名字" prop="firstname">
							<el-input v-model="clientEditForm.firstname"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="姓氏" prop="lastname">
							<el-input v-model="clientEditForm.lastname"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="银行" prop="bank.name">
							<el-input v-model="clientEditForm.bank.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="账户" prop="bank.account">
							<el-input v-model="clientEditForm.bank.account"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="邮件" prop="email">
							<el-input v-model="clientEditForm.email"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="电话" prop="mobile">
							<el-input v-model="clientEditForm.mobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="新密码" prop="password">
							<el-input type="password" v-model="clientEditForm.password"
								autocomplete="new-password">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="确认密码" prop="checkpass">
							<el-input type="password" v-model="clientEditForm.checkpass"
								autocomplete="new-password">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="22">
						<el-form-item label="备注" prop="note">
							<el-input type="textarea" placeholder="请输入内容" v-model="clientEditForm.note"
								maxlength="150" show-word-limit resize="none" :autosize="{ minRows: 3 }">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setClientEditDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editClientInfo">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="添加房东" :visible.sync="addClientDialogVisible" width="50%"
			@close="addClientDialogClosed">
			<el-form :model="clientAddForm" :rules="clientAddFormRules" ref="clientAddFormRef"
				label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="名字" prop="firstname">
							<el-input v-model="clientAddForm.firstname"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="姓氏" prop="lastname">
							<el-input v-model="clientAddForm.lastname"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="银行" prop="bank.name">
							<el-input v-model="clientAddForm.bank.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="账户" prop="bank.account">
							<el-input v-model="clientAddForm.bank.account"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="邮件" prop="email">
							<el-input v-model="clientAddForm.email"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="电话" prop="mobile">
							<el-input v-model="clientAddForm.mobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="密码" prop="password">
							<el-input type="password" v-model="clientAddForm.password"
								autocomplete="new-password">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="确认密码" prop="checkpass">
							<el-input type="password" v-model="clientAddForm.checkpass"
								autocomplete="new-password">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="22">
						<el-form-item label="备注" prop="note">
							<el-input type="textarea" placeholder="请输入内容" v-model="clientAddForm.note"
								maxlength="150" show-word-limit resize="none" :autosize="{ minRows: 3 }">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addClientDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addClient">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="添加房产" :visible.sync="addPropertyDialogVisable" width="50%"
			@close="addPropertyDialogClosed">
			<el-form :model="addPropertyForm" :rules="addPropertyFormRules" ref="addPropertyFormRef"
				label-width="100px">
				<el-form-item label="房东" prop="ownerusername">
					<el-input v-model="addPropertyForm.ownerusername" disabled style="width: 90%;"></el-input>
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input v-model="addPropertyForm.address" style="width: 90%;"
						placeholder="房产地址是唯一的，不能和已有房产重复"></el-input>
				</el-form-item>
				<el-row>
					<el-col :span="11">
						<el-form-item label="自付地税" prop="self_paid">
							<el-switch v-model="addPropertyForm.self_paid"></el-switch>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="是否在租" prop="is_leasing">
							<el-switch v-model="addPropertyForm.is_leasing"></el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" placeholder="请输入内容" v-model="addPropertyForm.note"
						maxlength="150" show-word-limit resize="none" :autosize="{ minRows: 3 }"
						style="width: 90%;">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addPropertyDialogVisable = false">取 消</el-button>
				<el-button type="primary" @click="addProperty">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'Clients',
	created() {
		this.getClientList();
	},
	data() {
		const validateDeleteInput = (rule, value, callback) => {
			if (value !== this.clientInfo.username) {
				callback(new Error('房东名字输入错误！'));
			} else {
				callback();
			}
		};
		const validatePass = (rule, value, callback) => {
			if (value !== this.clientAddForm.password) {
				callback(new Error('两次输入密码不一致！'));
			} else {
				callback();
			}
		};
		const editValidatePass = (rule, value, callback) => {
			if (!this.clientEditForm.password) {
				if (value) {
					callback(new Error('两次输入密码不一致！'));
				} else {
					callback();
				}
			} else if (value !== this.clientEditForm.password) {
				callback(new Error('两次输入密码不一致！'));
			} else {
				callback();
			}
		};
		return {
			queryInfo: {
				query: '',
				pagenum: 1,
				pagesize: 10,
				order: '',
				prop: ''
			},
			loading: true,
			clientList: [],
			total: 0,
			expandRowKeys: [],
			setClientDeleteDialogVisible: false,
			setClientEditDialogVisible: false,
			addClientDialogVisible: false,
			clientInfo: {},
			deleteForm: {
				deleteClient: ''
			},
			deleteFormRules: {
				deleteClient: [
					{required: true, message: '请输入房东姓名确认', trigger: 'blur'},
					{validator: validateDeleteInput, trigger: 'blur'}
				]
			},
			clientEditForm: {
				bank: {
					name: '',
					account: ''
				},
			},
			clientEditFormRules: {
				firstname: [
					{required: true, message: '请输入房东名字', trigger: 'blur'},
				],
				lastname: [
					{required: true, message: '请输入房东名字', trigger: 'blur'},
				],
				'bank.name': [
					{required: true, message: '银行名字不能为空', trigger: 'blur'},
				],
				'bank.account': [
					{required: true, message: '银行账户不能为空', trigger: 'blur'},
				],
				password: [
					{min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'}
				],
				checkpass: [
					{validator: editValidatePass, trigger: 'blur'}
				],
				email: [
					{required: true, message: '请输入邮箱', trigger: 'blur'},
					{type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur']}
				]
			},
			clientAddForm: {
				firstname: '',
				lastname: '',
				bank: {
					name: '',
					account: ''
				},
				email: '',
				mobile: '',
				note: '',
				password: '',
				checkpass: ''
			},
			clientAddFormRules: {
				firstname: [
					{required: true, message: '请输入房东名字', trigger: 'blur'},
				],
				lastname: [
					{required: true, message: '请输入房东姓氏', trigger: 'blur'},
				],
				'bank.name': [
					{required: true, message: '银行名字不能为空', trigger: 'blur'},
				],
				'bank.account': [
					{required: true, message: '银行账户不能为空', trigger: 'blur'},
				],
				password: [
					{required: true, message: '请输入密码', trigger: 'blur'},
					{min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'}
				],
				checkpass: [
					{required: true, message: '请确认密码', trigger: 'blur'},
					{validator: validatePass, trigger: 'blur'}
				],
				email: [
					{required: true, message: '请输入邮箱', trigger: 'blur'},
					{type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur']}
				]
			},
			addPropertyDialogVisable: false,
			addPropertyForm: {
				ownerusername: '',
				owner: '',
				address: '',
				self_paid: false,
				is_leasing: false,
				note: ''
			},
			addPropertyFormRules: {
				address: [
					{required: true, message: '请输入地址', trigger: 'blur'}
				]
			}
		};
	},
	methods: {
		async getClientList() {
			this.loading = true;
			const {data: res} = await this.$http.get('clients', {params: this.queryInfo});
			if (res.meta.status !== 200) return this.$message.error('获取房东列表失败！');
			this.clientList = res.data.clients;
			this.total = res.data.total;
			this.loading = false;
		},
		handleSizeChange(newSize) {
			this.queryInfo.pagesize = newSize;
			this.getClientList();
		},
		handleCurrentChange(newPage) {
			this.queryInfo.pagenum = newPage;
			this.getClientList();
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getClientList();
		},
		handleRowExpand(row) {
			if (this.expandRowKeys.length > 0 && this.expandRowKeys[0] === row.id) {
				this.expandRowKeys.length = 0;
			} else {
				this.expandRowKeys.length = 0;
				this.expandRowKeys.push(row.id);
			}
		},
		handleSearchClear() {
			this.queryInfo.pagenum = 1;
			this.getClientList();
		},
		async clientStateChanged(clientInfo) {
			const {data: res} = await this.$http.put(`clients/${clientInfo.id}/state/${clientInfo.mg_state}`);
			// console.log(res);
			if (res.meta.status !== 200) {
				clientInfo.mg_state = !clientInfo.mg_state;
				return this.$message.error('更新房东状态失败！');
			}
			this.$message.success('更新房东状态成功！');
		},
		showDeleteClientDialog(clientInfo) {
			this.clientInfo = clientInfo;
			this.setClientDeleteDialogVisible = true;
		},
		removeClientById() {
			this.$refs.deleteFormRef.validate(async valid => {
				if (!valid) return;

				const confirmResult = await this.$confirm('此操作将永久删除该房东, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).catch(err => err);

				// 如果用户确认删除， 则返回值为字符串 confirm
				// 如果用户取消了删除，则返回值为字符串 cancel
				if (confirmResult !== 'confirm') {
					this.setClientDeleteDialogVisible = false;
					return this.$message.info('已取消删除！');
				}
				const {data: res} = await this.$http.delete('clients/' + this.clientInfo.id);
				if (res.meta.status !== 200) {
					return this.$message.error(res.meta.msg);
				}

				this.$message.success('房东已成功删除！');
				this.getClientList();
				this.setClientDeleteDialogVisible = false;
			});
		},
		setClientDeleteDialogClosed() {
			this.$refs.deleteFormRef.resetFields();
		},
		async showClientEditDialog(clientInfo) {
			this.clientEditForm = {...clientInfo};
			this.setClientEditDialogVisible = true;
		},
		clientEditFormClosed() {
			this.$refs.clientEditFormRef.resetFields();
		},
		editClientInfo() {
			this.$refs.clientEditFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.put('clients/' + this.clientEditForm.id, this.clientEditForm);
				if (res.meta.status !== 200) {
					if (res.meta.status === 422) {
						return this.$message.error(res.meta.msg);
					} else {
						return this.$message.error('更新房东失败！');
					}
				}
				this.$message.success('更新房东成功！');
				this.setClientEditDialogVisible = false;
				this.getClientList();
			});
		},
		addClientDialogClosed() {
			this.$refs.clientAddFormRef.resetFields();
		},
		addClient() {
			this.$refs.clientAddFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.post('clients', this.clientAddForm);
				console.log(res);
				if (res.meta.status !== 201) {
					if (res.meta.status === 422) {
						return this.$message.error(res.meta.msg);
					} else {
						return this.$message.error('添加房东失败！');
					}
				}
				this.$message.success('添加房东成功！');
				this.addClientDialogVisible = false;
				this.getClientList();
			});
		},
		openAddPropertyDialog(ownerInfo) {
			this.addPropertyForm.ownerusername = ownerInfo.username;
			this.addPropertyForm.owner = ownerInfo.id;
			this.addPropertyDialogVisable = true;
		},
		routeToDetail(id) {
			this.$router.push(`/properties/detail/${id}`);
		},
		addPropertyDialogClosed() {
			this.$refs.addPropertyFormRef.resetFields();
		},
		addProperty() {
			this.$refs.addPropertyFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.post('properties', this.addPropertyForm);
				if (res.meta.status !== 201) {
					if (res.meta.status === 422) {
						return this.$message.error(res.meta.msg);
					} else {
						return this.$message.error('添加房产失败！');
					}
				}
				this.$message.success('添加房产成功！');
				this.addPropertyDialogVisable = false;
				this.getClientList();
			});
		}
	}
}
</script>

<style lang="less" scoped>
.el-descriptions:first-child {
	padding: 20px;
}
.el-descriptions:last-child {
	padding: 0 20px 36px;
}
.property-slot {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.delete-form {
	margin-top: 20px;
}
a {
	text-decoration: none;
	color: #606266;
}
</style>