export default {
  namespaced: true,
  actions: {
  },
  mutations: {
    SAVE_BASEINFO(state, value) {
      state.propertyBaseInfo = value;
    }
  },
  state: {
    propertyBaseInfo: {}
  },
  getters: {
  },
};